.ContactUs {
  background: #9AA1A6;
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.container {
  position: relative;
  padding: 72px 0;
  margin: 0 auto;
  width: 80%;

  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
}

.left {
  position: relative;
  z-index: 99999;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #fff;

  .icon {
    img {
      height: 120px;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 110%;
  }

  p {
    font-size: 18px;
    line-height: 24px;
  }
}

.triangle1 {
  z-index: 9999;
  position: absolute;
  top: -50px;
  left: 5%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 14px 24.2px 14px;
  border-color: transparent transparent #E13238 transparent;
  transform: rotate(136deg);
}

.triangle2 {
  z-index: 9999;
  position: absolute;
  left: 60%;
  bottom: -70px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 21px 36.4px 21px;
  border-color: transparent transparent #FFCDC8 transparent;
  transform: rotate(15deg);
}

.greyTriangle {
  z-index: 9999;
  position: absolute;
  width: 282px;
  transform: rotate(71.922deg);
  right: 10%;
  top: 0;
}

.right {
  position: relative;
  z-index: 99999;
  max-width: 374px;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 8px 50px 0 rgba(98, 98, 98, 0.10);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.success {
  text-align: center;
}

.errorField {
  color: #EF4444;
  text-align: center;
}

@media (max-width: 1200px) {

}

@media (max-width: 1024px) {

}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 32px 0;
    width: 90%;
    gap: 24px;
  }


  .left {
    width: 100%;
    gap: 8px;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }

  .right {
    order: 1;
    width: 100%;
    max-width: 500px;
  }

  .triangle1, .triangle2, .greyTriangle {
    display: none;
  }
}
