.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background: #E13238;
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &:hover {
    background: #DB3D3C;
  }

  &:active {
    background: #B13633;
  }

  &:disabled {
    opacity: 0.3;
  }
}


