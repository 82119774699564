.FormInput {
  width: 100%;
  padding: 8px 16px;

  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  background: #FFF;
  border-radius: 4px;
  border: 1px solid #E1E5E8;

  &:focus {
    border: 1px solid #E13238;
    box-shadow: 0 0 0 3px rgba(255, 126, 118, 0.2);
  }
}
