.Footer {
  width: 100%;
  background: #202930;
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.container {
  width: 82%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 32px 0;
  border-bottom: 1px solid #40484E;
}

.left {
  flex-shrink: 0;

  img {
    width: 170px;
  }
}

.right {
  display: flex;
  gap: 24px;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.icon {
  width: 24px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 16px;

  color: #798288;
  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.link {
  color: #798288;
  cursor: pointer;
  text-decoration: underline!important;
  text-underline-offset: 2px;
}

.paySystems {
  flex-shrink: 0;

  img {
    height: 40px;
  }

}

.bottom {
  padding: 24px 0;
  display: flex;
  justify-content: center;

  color: #798288;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}


@media (max-width: 1024px) {
  .top, .bottom {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
  }

  .top {
    gap: 16px;
  }

  .right {
    flex-direction: column;
  }
  .bottom {
    justify-content: flex-start;
  }

  .block1 {
    order: 1;
  }

  .text {
    gap: 12px;
  }
}


@media (max-width: 600px) {
  .top, .bottom {
    padding: 24px 0;
  }
}
