.Features {
  background: #F5F5F5;
}

.wrapper {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
}

.container {
  position: relative;
  margin: 0 auto;
  width: 80%;
  padding: 90px 0 0 0;

  display: flex;
  justify-content: space-between;
  gap: 40px;
  color: #fff;
}

.left {
  width: 50%;

  img {
    max-height: 417px;
  }
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #202930;

  h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 110%;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  a {
    text-align: center;
    padding: 17px 24px;
    color: #fff;
    line-height: 24px;
    border-radius: 4px;
    background: #202930;
    width: 230px;

    &:hover {
      background: #E13238;
    }
  }
}

.triangle1 {
  position: absolute;
  transform: rotate(-47.397deg);
  left: -17%;
  top: 40%;
  width: 134px;
}
.triangle2 {
  position: absolute;
  right: -10%;
  top: 50%;
  width: 72px;
}
.triangle3 {
  position: absolute;
  right: -23%;
  bottom: -200px;
  transform: rotate(118.285deg);
  width: 280px;
}

@media (max-width: 1200px) {

}

@media (max-width: 1024px) {

}

@media (max-width: 768px) {
  .container {
    width: 90%;
    padding: 32px 0;
    flex-direction: column;
    gap: 16px;
  }

  .left {
    width: 100%;

    img {
      margin: 0 auto;
      display: block;
    }
  }

  .right {
    width: 100%;

    h2 {
      font-size: 24px;
      line-height: 117%;
    }
    p {
      font-size: 16px;
    }
    a {
      padding: 13px 24px;
    }
  }

  .triangle1, .triangle2, .triangle3 {
    display: none;
  }
}

@media (max-width: 600px) {
  .right {
    a {
      width: 100%;
    }
  }
}
