.NavBar {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 72px;
  background: #FFF;
  color: #202930;
}

.wrapper {
  height: 100%;
  display: flex;
  max-width: 1600px;
  margin: 0 auto;
}

.container {
  margin: 0 auto;
  width: 80%;

  display: flex;
  align-items: center;
  gap: 32px;

  img {
    position: relative;
    z-index: 999;
  }
}

.navLinks {
  display: flex;
  align-items: center;
  width: 100%;
}

.list {
  display: flex;
  gap: 24px;
  width: 100%;
}

.link {
  color: #202930;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &:hover {
    color: #B72C2F;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.apiLink {
  height: 40px;
  padding: 8px 12px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  border-radius: 4px;
  background: #202930;
  text-align: center;

  &:hover {
    background: #E13238;
  }
}

.accountLink {
  min-width: 170px;
  text-align: center;
  color: #fff;
  font-weight: 400;
  line-height: 24px;
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  background: #E13238;

  &:hover {
    background: #202930;
  }
}


.navBurger {
  position: relative;
  z-index: 999;
  display: none;
  width: 40px;
  height: 40px;
  background: url("../../assets/images/IconBurger.svg");
}

.burgerClose {
  background: url("../../assets/images/IconBurgerClose.svg");
}

@media (max-width: 1200px) {
  .container {
    gap: 24px;
  }

  .buttons {
    gap: 12px;
  }
}

@media (max-width: 1110px) {
  .NavBar {
    height: 60px;
  }

  .container {
    width: 90%;
    justify-content: space-between;
  }

  .navLinks {
    display: none;
    background: #202930;
    position: absolute;
    z-index: 100;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    padding: 0 10%;
  }

  .list {
    margin: 10% auto;
    flex-direction: column;
    font-size: 16px;
    text-align: center;
    justify-content: flex-start;
  }

  .link {
    color: rgba(255, 255, 255, 0.72);


    &:hover {
      color: #B72C2F;
    }
  }

  .apiLink {
    color: #202930;
    background: #FFF;

    &:hover {
      background: #E13238;
    }
  }

  .navLinksShow {
    display: flex;
  }

  .navBurger {
    display: block;
  }
}

@media (max-width: 600px) {
  .buttons {
    flex-direction: column;
    width: 100%;

    a {
      width: 100%;
    }
  }

  .list {
    text-align: start;
  }
}
