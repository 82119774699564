.AFT {
  color: #202930;
}

.SBP {
  color: #202930;
  background: #F5F5F5;
}

.ECOM {
  color: #fff;
  background: #202930;
}

.P2P {
  color: #202930;
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.container {
  position: relative;
  margin: 0 auto;
  width: 80%;
  padding: 80px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;

  h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 110%;
  }

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}

.topList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  width: 100%;
}

.topItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 130px;
}

.triangleIcon {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 26.0px 15px;
  border-color: transparent transparent #E13238 transparent;
  transform: rotate(180deg);
}

.topItemText {
  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
  }

  p {
    margin-top: 4px;
    font-size: 18px;
    line-height: 24px;
  }
}

.tech {
  display: flex;
  flex-direction: column;
  gap: 40px;

  h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 125%;
  }
}

.techList {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.techCol {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  min-width: 300px;
  width: 100%;
}

.techItem {
  display: flex;
  align-items: flex-start;
  gap: 14px;
}

.techItemText {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
  }
  p {
    font-size: 18px;
    line-height: 24px;
  }
}

.redText {
  text-align: center;
  color: #B72C2F;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 120%;
}

.triangleAFT {
  position: absolute;
  transform: rotate(-5.397deg);
  left: -20%;
  top: 35%;
  width: 204px;
}
.triangleAFT2 {
  position: absolute;
  transform: rotate(-95.397deg);
  right: -12%;
  width: 72px;
  bottom: 10%;
}
.triangleSBP {
  position: absolute;
  transform: rotate(45.397deg);
  left: -10%;
  top: 45%;
  width: 72px;
}
.triangleECOM {
  position: absolute;
  transform: rotate(-35.397deg);
  right: -20%;
  width: 164px;
  bottom: 15%;
}
.triangleP2P {
  position: absolute;
  transform: rotate(125.397deg);
  left: -15%;
  top: 20%;
  width: 76px;
}
.triangleP2P2 {
  position: absolute;
  transform: rotate(-95.397deg);
  right: -15%;
  width: 128px;
  bottom: -3%;
}

@media (max-width: 1200px) {

}

@media (max-width: 1024px) {
  .techCol {
    min-width: 250px;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    padding: 32px 0;
    gap: 32px;
  }

  .top {
    gap: 32px;
  }

  .title {
    gap: 8px;
    text-align: start;

    h2 {
      font-size: 24px;
      line-height: 117%;
    }
    p {
      font-size: 16px;
    }
  }

  .triangleIcon {
    border-width: 0 8px 13.9px 8px;
  }

  .topList {
    gap: 24px;
  }

  .topItem {
    gap: 12px;
  }

  .topItemText {
    h3 {
      font-size: 18px;
      line-height: 24px;
    }
    p {
      font-size: 16px;
    }
  }

  .tech {
    gap: 24px;

    h2 {
      text-align: start;
      font-size: 24px;
      line-height: 117%;
    }
  }

  .techList, .techCol {
    gap: 24px;
  }

  .techCol {
    min-width: 220px;
  }

  .techItem {
    gap: 8px;
  }

  .techItemText {
    h3 {
      font-size: 18px;
      line-height: 24px;
    }
    p {
      font-size: 16px;
    }
  }

  .redText {
    text-align: start;
    font-size: 18px;
    line-height: 24px;
  }

  .triangleAFT, .triangleAFT2, .triangleSBP, .triangleECOM, .triangleP2P, .triangleP2P2 {
    display: none;
  }
}

@media (max-width: 600px) {
  .techCol {
    min-width: 170px;
  }
}

@media (max-width: 450px) {
  .techCol {
    min-width: 130px;
  }
}
