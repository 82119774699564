.OurMission {
  background: linear-gradient(180deg, #F5F5F5 50%, rgba(0, 0, 0, 0) 50%);
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.container {
  position: relative;
  margin: 0 auto;
  width: 80%;
  padding: 64px 0;
}

.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px;
  border-radius: var(--radius-l, 12px);
  background: var(--bg-button-dark-main, #202930);
  box-shadow: 0 8px 50px 0 rgba(7, 16, 23, 0.10);
}

.left {
  position: relative;
  z-index: 10;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #fff;

  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 125%;
  }

  p {
    font-size: 18px;
    line-height: 24px;
  }
}

.right {
  position: relative;
  width: 50%;
}
.pic {
  position: relative;
  z-index: 10;
  max-height: 190px;
  display: block;
  margin: 0 auto;
}

.gradient {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-height: 350px;
}

.greyTriangle1 {
  z-index: 9999;
  position: absolute;
  width: 144px;
  transform: rotate(-51.922deg);
  left: 10%;
  bottom: -110px;
}
.greyTriangle2 {
  z-index: 9999;
  position: absolute;
  width: 164px;
  transform: rotate(-51.922deg);
  right: 5%;
  top: -90px;
}
.triangle1 {
  z-index: 9999;
  position: absolute;
  left: 20%;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 17.3px 10px;
  border-color: transparent transparent #FFCDC8 transparent;
  transform: rotate(15deg);
}

.triangle2 {
  z-index: 9999;
  position: absolute;
  top: 5%;
  right: 20%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 13.9px 8px;
  border-color: transparent transparent #E13238 transparent;
  transform: rotate(106deg);
}

@media (max-width: 1200px) {
  .greyTriangle1 {
    left: -10%;
  }
  .greyTriangle2 {
    right: -10%;
  }
}

@media (max-width: 1024px) {
  .greyTriangle1 {
    left: -15%;
  }
  .greyTriangle2 {
    right: -20%;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0;
  }

  .block {
    display: flex;
    flex-direction: column;
    padding: 32px 20px;
    gap: 16px;
    border-radius: 0;
    box-shadow: none;
  }

  .left {
    order: 1;
    width: 100%;

    h2 {
      font-size: 24px;
      line-height: 117%;
    }
    p {
      font-size: 16px;
    }
  }

  .right {
    width: 100%;

    .pic {
      max-height: 110px;
      margin: 30px auto;
    }
  }

  .greyTriangle1 {
    bottom: 0;
    left: 2%;
  }
  .greyTriangle2 {
    top: 0;
    right: -10%;
    transform: rotate(-1.2deg);
  }
  .triangle1 {

  }
  .triangle2 {

  }
}

@media (max-width: 430px) {
  .greyTriangle1 {
    left: -15%;
  }
  .greyTriangle2 {
    right: -25%;
  }
}
@media (max-width: 350px) {
  .greyTriangle1 {
    left: -25%;
  }
  .greyTriangle2 {
    right: -45%;
  }
}
