.parallax {
  /* The image used */
  background-image: url("../../../assets/images/background.jpg");
  background-size: cover;
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.container {
  position: relative;
  margin: 0 auto;
  width: 80%;
  padding: 100px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  color: #fff;
}

.left {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 117%;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
}

.right {
  position: relative;
  padding: 0 80px;
}
.pic {
  position: relative;
  z-index: 40;
}

.gradient {
  z-index: 20;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.triangle1 {
  position: absolute;
  left: -20%;
  bottom: 10%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 17.3px 10px;
  border-color: transparent transparent #FFCDC8 transparent;
  transform: rotate(15deg);
}

.triangle2 {
  position: absolute;
  bottom: -40%;
  right: 10%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 13.9px 8px;
  border-color: transparent transparent #E13238 transparent;
  transform: rotate(106deg);
}

.triangle3 {
  position: absolute;
  bottom: -15px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 21.5px 37.2px 21.5px;
  border-color: transparent transparent #E13238 transparent;
  transform: rotate(10deg);
}


@media (max-width: 1200px) {
  .right {
    padding: 0 40px;
  }
}

@media (max-width: 1024px) {
  .right {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    padding: 52px 0 32px 0;
    gap: 12px;
    flex-direction: column;
  }

  .left {
    order: 1;

    h1 {
      font-size: 36px;
      line-height: 110%;
    }
  }

  .right {
    padding: 0 30px;

    img {
      max-height: 410px;
    }
  }

  .triangle1, .triangle2, .triangle3 {
    display: none;
  }

}

@media (max-width: 600px) {
  .parallax {
    background-attachment: inherit;
  }
}
